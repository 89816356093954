<template>
  <div class="input-wrap" :class="{ 'has-error':inError }">
    <label v-if="label" class="input-label">{{ label }}</label>
    <div class="radio" ref="input">
      <label v-for="(option, i) in options" :key="`${name}-${i}`">
        <input
          v-if="type === 'multiple_select'"
          class="input-radio"
          type="checkbox"
          :name="name"
          v-model="values"
          :value="option.value"
          @change="input"
        ><input
          v-else
          class="input-radio"
          type="radio"
          :name="name"
          :value="option.value"
          :checked="option.value === value"
          @change="input"
        >
        <span class="radio-click">{{ option.label ? option.label : option.text }}</span>
      </label>
    </div>

    <template v-if="errorText && inError">
      <span class="error">{{ errorText }}</span>
    </template>
  </div>
</template>

<script>
  export default {
    name: "radio-field",
    props: {
      value: { default: '' },
      type: { default: 'radio_group' },
      label: { default: null },
      name: { default: null, required: true },
      errorText: { default: null },
      inError: { default: false },
      options: { default() { return [] } }
    },
    data() {
      return {
        values: []
      }
    },
    methods: {
      input(event) {
        if (this.type === 'multiple_select') {
          this.$emit('input', this.values);
          this.$emit('change', this.values);
        } else {
          const val = ['true','false'].indexOf(event.target.value) > -1 ? event.target.value === 'true' : event.target.value
          this.$emit('input', val);
          this.$emit('change', val);
        }
      },
      focusOn() {
        this.$refs.input.focus();
      }
    }
  }
</script>