import axios from 'axios';

const instance = axios.create({
  xsrfCookieName: 'CRAFT_CSRF_TOKEN',
  xsrfHeaderName: 'X-CSRF-Token'
});

// Create a closure to store the token value and a flag indicating whether the token has been fetched or not
const getToken = (function () {
  let tokenValue = null;
  let tokenFetched = false;

  return function () {
    if (tokenFetched) {
      // If the token has already been fetched, return it as a resolved Promise
      return Promise.resolve(tokenValue);
    } else {
      // Fetch the token and store it in the closure
      return fetch('/actions/blitz/csrf/token')
        .then(result => result.text())
        .then(token => {
          tokenValue = token;
          tokenFetched = true;
          return token;
        })
        .catch(() => {
          throw new Error('X-CSRF-Token could not be retrieved');
        });
    }
  };
})();

instance.interceptors.request.use(function (config) {
  // Get the token from the closure and set the X-CSRF-Token header in the config object
  return getToken().then(token => {
    config.headers['X-CSRF-Token'] = token;
    return config;
  });
});

export default instance;
