import * as bodyScrollLock from 'body-scroll-lock';

const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const bodyScrollLockOptions = { reserveScrollBarGap: true };

const scrollLock = function (el) {
  window.lockedEl = el
  disableBodyScroll(el, bodyScrollLockOptions)
}

const scrollUnLock = function () {
  if (window.lockedEl) {
    enableBodyScroll(window.lockedEl, bodyScrollLockOptions)
  }
}

export { scrollLock, scrollUnLock }