function classy(element) {
	var el = typeof element == 'string' ? Array.prototype.slice.call(document.querySelectorAll(element), 0) : typeof element == 'object' && typeof element.length == 'undefined' ? [element] : element;
	var elType = typeof element == 'string' ? 'selector' : false;

	var forEach = function(array, callback) {
		for (var i = 0; i < array.length; i++) {
			callback(array[i], i);
		};
	};

	function addClass(el, className) {
		if (el) {
			if (el.classList) el.classList.add(className);
			else el.className += ' ' + className;
		};
	};

	function removeClass(el, className) {
		if (el) {
			if (el.classList) el.classList.remove(className);
			else el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		};	
	};

	function toggleClass(el, className) {
		if (el) {
			if (el.classList) el.classList.toggle(className);
			else {
				var classes = el.className.split(' ');
				var existingIndex = classes.indexOf(className);
				if (existingIndex >= 0) classes.splice(existingIndex, 1);
				else classes.push(className);
				el.className = classes.join(' ');
			};
		};
	};

	return {
		addCl: 		function(className) { addClass(el ? el[0] : false, className);    return this; },
		rmCl: 		function(className) { removeClass(el ? el[0] : false, className); return this; },
		toCl: 		function(className) { toggleClass(el ? el[0] : false, className); return this; },
		addCls: 	function(className) { forEach(el, function(el, i) { addClass(el, className)		}); return this; },
		rmCls: 		function(className) { forEach(el, function(el, i) { removeClass(el, className)	}); return this; },
		toCls: 		function(className) { forEach(el, function(el, i) { toggleClass(el, className) 	}); return this; },
	}
};

export { classy }