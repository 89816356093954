<template>
  <div class="form-wrap">
    <div class="success-over section is-deeper" :class="{ show:submitted, 'is-red':!propHandle }">
      <div class="text-center max550 m-auto" v-if="form.handle === 'mailingList'">
        <h2 class="title is-2">Thanks for joining our mailing list.</h2>
      </div>
      <div class="text-center max550 m-auto" v-if="form.handle != 'mailingList'">
        <h2 class="title is-2">Thanks for your message</h2>
        <p>We'll contact you as soon as possible</p>
      </div>
    </div>
    <template v-if="form">
      <!-- HubSpot forms now replacing Craft Commerce cart etc. More info on this method in templates/homes/entry.twig -->
      <template v-if="form.handle === 'rent'">
        <hubspot-forms></hubspot-forms>
      </template>   
      <template v-else>
        <form @submit.prevent="submit" v-if="fields" ref="form">
          <div v-for="(row, rowInd) in form.rows" class="input-line">
            <template v-for="field in row">
              <google-autocomplete
                v-if="field.handle === 'address'"
                v-model.trim="$v.fields[field.handle].$model"
                :label="field.label"
                placeholder="Start typing address"
                country="NZ"
                :error-text="errorText($v.fields[field.handle])"
                :in-error="$v.fields[field.handle].$error"
                :class="{ 'is-required':field.required }"
              />
              <text-field
                v-else-if="field.handle === 'unitType'"
                type="select"
                :label="field.label"
                :options="isBuy ? products : rentals"
                :name="field.handle"
                v-model.trim="$v.fields[field.handle].$model"
                :error-text="errorText($v.fields[field.handle])"
                :in-error="$v.fields[field.handle].$error"
                :class="{ 'is-required':field.required }"
              />
              <checkbox-field
                v-else-if="field.type === 'checkbox' || field.type === 'checkbox_group'"
                :type="field.type"
                :label="field.label"
                v-model.trim="$v.fields[field.handle].$model"
                :options="field.options"
                :name="field.handle"
                :error-text="errorText($v.fields[field.handle])"
                :in-error="$v.fields[field.handle].$error"
                :class="{ 'is-required':field.required }"
              />
              <radio-field
                v-else-if="field.type === 'radio_group' || field.type === 'multiple_select'"
                :type="field.type"
                :label="field.label"
                v-model.trim="$v.fields[field.handle].$model"
                :options="field.options"
                :name="field.handle"
                :error-text="errorText($v.fields[field.handle])"
                :in-error="$v.fields[field.handle].$error"
                :class="{ 'is-required':field.required }"
              />
              <text-field
                v-else-if="['select','textarea','text','email','number'].indexOf(field.type) > -1"
                :type="field.type"
                :label="field.label"
                :options="field.options"
                :name="field.handle"
                v-model.trim="$v.fields[field.handle].$model"
                :error-text="errorText($v.fields[field.handle])"
                :in-error="$v.fields[field.handle].$error"
                :class="{ 'is-required':field.required }"
              />
              <div v-else-if="field.type === 'recaptcha'" class="input-wrap" :class="{ norecapt:hasError && !gresponse }">
                <vue-recaptcha
                  :sitekey="getSiteKey(field)"
                  ref="recaptcha"
                  @verify="onVerify"
                />
                <span class="error" v-if="hasError && !gresponse">Field is required</span>
              </div>
              <div v-else-if="field.type === 'html'" class="input-wrap">
                <div class="text-form-block wysiwyg" v-html="field.value"></div>
              </div>
              <div v-else-if="field.type === 'submit'" class="input-wrap">
                <button type="submit" class="button has-loader" :class="{ 'is-loading':sending }" :disabled="sending">{{ field.label }}</button>
              </div>
              <div v-else class="input-wrap">
                No field for type: "{{ field.type }}" available<br>
                <code>{{ field }}</code>
              </div>
            </template>
          </div>
          <div class="input-line" v-if="errorMessage">
            <div class="title is-red is-bold">{{ errorMessage }}</div>
          </div>
        </form>
      </template>
    </template>
    <template v-else>
      <div style="height: 300px">
        <span class="has-loader is-text is-loading">Loading the form ...</span>
      </div>
    </template>
  </div>
</template>

<script>
  import { app } from './index'
  import Downloader from 'js-file-downloader';
  import { required, email, numeric } from 'vuelidate/lib/validators'
  import VueRecaptcha from 'vue-recaptcha'
  import { CollapseTransition } from 'vue2-transitions'
  import CheckboxField from './components/checkbox-field.vue'
  import TextField from './components/text-field.vue'
  import RadioField from './components/radio-field.vue'
  import GoogleAutocomplete from './components/google-autocomplete.vue'
  import HubspotForms from './HubspotForms.vue'

  export default {
    name: "App",
    components: { CheckboxField, TextField, RadioField, GoogleAutocomplete, VueRecaptcha, CollapseTransition, HubspotForms },
    validations() {
      const validations = {}
      this.form.rows.forEach(row => {
        row.forEach(field => {
          const validation = {}
          if (field.required) validation['required'] = required
          if (field.type === 'number') validation['numeric'] = numeric
          if (field.handle === 'email') validation['email'] = email
          if (field.handle === 'phone') validation['phone'] = (value) => value ==='' || /^[0-9()\-+\s]+$/.test(value)
          validations[field.handle] = validation
        })
      })

      return { fields: validations }
    },
    props: {
      propHandle: {
        default: false
      },
      propUnit: {
        default: false
      }
    },
    data() {
      return {
        fields: {},
        hasError: false,
        gresponse: null,
        submitted: false,
        sending: false,
        errorMessage: null
      }
    },
    computed: {
      handle() { return this.propHandle || this.$root.handle },
      fileName() { return this.$root.fileName },
      unit() { return this.propUnit || this.$root.unit },
      content() { return app.content },
      products() {
        return app.content ? app.content.products.map(p => {
          return {
            name: p.title,
            value: p.title
          }
        }) : []
      },
      rentals() {
        return app.content ? app.content.rentals.map(p => {
          return {
            name: p.title,
            value: p.title
          }
        }) : []
      },
      form() {
        const form = this.content ? this.content.forms.find(f => f.handle === this.handle) : null;
        return form ? form : null
      },
      isBuy() {
        return this.fields['buyOrRent'] && this.fields['buyOrRent'] === 'Buy'
      }
    },
    watch: {
      form: {
        immediate: true,
        handler(form) {
          this.parseFields(form)
        }
      },
      unit() {
        this.parseFields(this.form)
      }
    },
    methods: {
      submit() {
        this.$v.fields.$touch()
        this.$nextTick(() => app.focusFirstStatus(this))

        this.hasError = Object.values(this.fields).some(f => f === 'recaptcha') && !this.gresponse
          || this.$v.fields.$invalid

        if (!this.hasError) {
          const data = {
            action: 'freeform/submit',
            formHash: this.form.formHash,
            ...this.fields
          }
          if (this.form.honeypot.name) {
            data[this.form.honeypot.name] = ''
          }
          if (this.gresponse) {
            data['g-recaptcha-response'] = this.gresponse
          }
          this.sending = true
          app.craftPost(data, true).then(() => {
            setTimeout(() => {
              this.submitted = true
              this.sending = false

              if (this.fileName) {
                new Downloader({ url: `/api/asset/${this.fileName}` })
              }

              if (this.propHandle) {
                setTimeout(() => {
                  location.reload();
                  //this.submitted = false
                  //this.$refs.form.reset()
                  //this.$emit('close')
                }, 5000)
              }

              // send event trigger to GTM
              window.dataLayer.push({
                "event": "submission-" + this.form.handle,
                "page": {
                  "path": window.location.pathname,
                  "title": document.title
                }
              });


            }, 700)
          }).catch(error => {
            this.sending = false
            this.submitted = false
            this.errorMessage = error
          })
        }
      },
      parseVal(field) {
        return field.type === 'checkbox' && field.value === '' ? false
          : field.type === 'checkbox' && field.value === 'Yes' ? true
          : field.value
      },
      parseFields(form) {
        this.fields = form ? [].concat(...form.rows).filter(f => f.type !== 'submit').reduce((obj, field) => {
          const isProductPage = !!(field.handle === 'unitType' && this.unit && this.rentals.length)
          const product = isProductPage ? this.content.rentals.find(p => p.slug === this.unit) : null
          return {
            ...obj,
            [field.handle]: product ? product.title : this.parseVal(field)
          }
        }, {}) : []
      },
      errorText(field) {
        return field.$params.hasOwnProperty('required') && !field.required ? 'Field is required'
          : field.$params.hasOwnProperty('numeric') && !field.numeric ? 'Should be a number'
          : field.$params.hasOwnProperty('phone') && !field.phone ? 'Should be valid phone no.'
          : field.$params.hasOwnProperty('email') && !field.email ? 'Should be email'
          : ''
      },
      getSiteKey(field) {
        if (!field.tag) { return '' }
        const temp = document.createElement('div')
        temp.innerHTML = field.tag
        return temp.children[0].dataset.sitekey
      },
      onVerify(resp) {
        this.gresponse = resp
      }
    }
  }
</script>