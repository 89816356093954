<template>
  <div class="estimation-modal" :class="{ open }" ref="enquiry" @click.self="close">
    <div class="close rounded-button" @click="close"></div>
    <template v-if="formHandle">
      <div class="modal-inner is-narrow">
      <!--   <Forms :prop-handle="formHandle" :propUnit="unit" @close="close" />-->
      </div>
    </template>
    <template v-else>
      <transition name="fade" mode="out-in">
        <div v-if="submitted" class="estimation-overlay" :class="{ submitted }" key="submitted">
          <div class="text-center max550 m-auto">
            <h2 class="title is-2">Thanks for your enquiry</h2>
            <p>We'll contact you as soon as possible</p>
            <span class="button" @click="close">Close</span>
          </div>
        </div>
        <div v-else-if="cart && product" class="modal-inner is-narrow" key="enquiry">
          <!-- HubSpot forms now replacing Craft Commerce cart etc. More info on this method in templates/homes/entry.twig -->
          <hubspot-forms ></hubspot-forms>
          <!-- <h2 class="title is-2">{{ product.title }} <small>Specify & Quote Delivery</small></h2>
          <form class="row buyform" method="post" @submit.prevent="submit">
            <div class="col-lg-8">
              <div class="is-bold is-upper mb-2 mt-4">Step 1: Delivery Region</div>
              <div class="details" autocomplete="off">
                <div class="input-line">
                  <text-field
                    v-model="enquiry.shippingMethodHandle"
                    label="Region"
                    type="select"
                    :options="shippingMethods"
                    @change="saveFields"
                    error-text="Field is required"
                    :in-error="$v.enquiry.$error && !$v.enquiry.shippingMethodHandle.required"
                  />
                </div>
              </div>

              <div class="is-bold is-upper mb-2 mt-4">Step 3: Choose optional extras</div>
              <div class="extras-wrap">

                <div v-for="extra in extras" :key="extra.pid" class="extra" :class="{ selected:extra.added }">
                  <checkbox-field v-model="extra.added" :label="extra.title" @input="updateExtrasInCart(extra, $event)" />
                  <div class="select" v-if="extra.multiple">
                    <select v-model="extra.qty" @change="updateExtrasInCart(extra, true)">
                      <option :value="i" v-for="i in 10" :key="`opt-${extra.pid}-${i}`">{{ i }}</option>
                    </select>
                  </div>
                  <div class="is-bold">{{ extra.price }}{{ extra.multiple ? ' ea' : '' }}</div>
                </div>
              </div>
              <div class="is-bold is-upper mb-2 mt-4">Step 3: Your Details</div>
              <div class="details" autocomplete="off">
                <div class="input-line">
                  <text-field
                    v-model="enquiry.firstName"
                    label="First name"
                    placeholder="Enter first name"
                    @change="saveFields"
                    error-text="Field is required"
                    :in-error="$v.enquiry.$error && !$v.enquiry.firstName.required"
                  />
                  <text-field
                    v-model="enquiry.lastName"
                    label="Last name"
                    placeholder="Enter last name"
                    @change="saveFields"
                  />
                </div>
                <div class="input-line">
                  <text-field
                    v-model="enquiry.email"
                    label="Email address"
                    placeholder="Enter your email"
                    @change="saveFields"
                    :error-text="$v.enquiry.email.required ? 'Must be valid email' : 'Field is required'"
                    :in-error="$v.enquiry.$error && !$v.enquiry.email.required || $v.enquiry.$error && !$v.enquiry.email.email"
                  />
                  <text-field
                    v-model="enquiry.phone"
                    type="tel"
                    label="Phone"
                    placeholder="Enter your phone"
                    @change="saveFields"
                  />
                </div>
                <div class="input-line">
                  <google-autocomplete
                    v-model="enquiry.addressText"
                    label="Site address"
                    placeholder="Start typing address"
                    country="NZ"
                    @placechanged="updateAddress"
                  />
                </div>
                <div class="input-line">
                  <div class="radio-row">
                   <! -- <radio-field
                      name="accessibleByTruck"
                      v-model="enquiry.accessibleByTruck"
                      :options="options"
                      label="Accessible by truck"
                      @change="saveFields"
                    /> -- >
                    <radio-field
                      name="doYouOwnASite"
                      v-model="enquiry.doYouOwnASite"
                      :options="options"
                      label="Do you own a site"
                      @change="saveFields"
                    />
                  </div>
                </div>
                <div class="input-line">
                  <text-field
                    v-model="enquiry.userMessage"
                    label="Message"
                    type="textarea"
                    placeholder="Enter your message here"
                    @change="saveFields"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-4 is-relative">
              <div class="summary-wrap sticky">
                <div class="is-bold is-upper mb-2">Summary</div>
                <div class="summary">
                  <div class="summary-row l-2">
                    <div>Unit: <small>{{ product.title }}</small></div>
                    <div class="is-bold">{{ product.price }}</div>
                  </div>
                  <div class="summary-row l-2">
                    <div>Selected extras</div>
                    <div class="is-bold" v-text="app.money(selectedExtras)"></div>
                  </div>
                  <div class="summary-row l-2">
                    <div>Delivery quote</div>
                    <div class="is-bold">{{ !enquiry.shippingMethodHandle ? '&mdash;' : cart.totalShippingCost ? cart.totalShippingCostAsCurrency : 'TBC' }}</div>
                  </div>
                  <div class="summary-row mt-4">
                    <div>
                      <span class="l-1">Total</span><br>
                      <span class="sm-c">*all gst inclusive</span>
                    </div>
                    <div class="is-bold l-1">{{ cart.totalTaxablePriceAsCurrency }}</div>
                  </div>
                  <div class="summary-row mt-4">
                    <div>
                      <span class="sm-c">HouseMe requires the contact information you provide to us to contact you about our products and services. 
                      You may unsubscribe from these communications at anytime. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, check out our <a href="/about-us/privacy-policy" data-barba-prevent="" target="_blank">Privacy Policy</a>.</span>
                    </div>
                  </div>
                  <button type="submit" class="button is-full mt-3 has-loader" :class="{ 'is-loading':sending }">Submit enquiry</button>
                </div>
              </div>
            </div>
          </form> -->
        </div>
        <div v-else class="loader">
          <div class="swiper-lazy-preloader"></div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>

  import { required, email } from 'vuelidate/lib/validators';
  import CheckboxField from './components/checkbox-field.vue'
  import TextField from './components/text-field.vue'
  import RadioField from './components/radio-field.vue'
  import GoogleAutocomplete from './components/google-autocomplete.vue'
  import { scrollUnLock, scrollLock } from '../helpers/scrollLock'
  // import Forms from './Forms.vue'
  import HubspotForms from './HubspotForms.vue'

  export default {
    name: "App",
    components: { CheckboxField, TextField, RadioField, GoogleAutocomplete,
      //  Forms,
       HubspotForms },
    validations: {
      emailPhoneVal: { required },
      enquiry: {
        email: { required, email },
        firstName: { required },
        shippingMethodHandle: { required }
      }
    },
    data() {
      return {
        enquiry: {
          addressText: null,
//          accessibleByTruck: null,
          doYouOwnASite: null,
          userMessage: null,
          email: null,
          firstName: null,
          lastName: null,
          phone: null,
          address1: null,
          address2: null,
          address3: null,
          city: null,
          zipCode: null,
          shippingMethodHandle: null,
          hubspotVars: null
        },
        sending: false,
        listener: false,
        address: null,
        shippingMethods: [],
        submitted: false,
        emailPhoneVal: '',
        inError: false,
        stopEnterRun: false,
        options: [
          { text: 'Yes', value: true },
          { text: 'No', value: false }
        ]
      }
    },
    computed: {
      app() { return this.$root },
      cart() { return this.app.cart },
      open() { return this.app.open },
      formHandle() { return this.app.formHandle },
      unit() { return this.app.unit },
      product() { return this.app.product },
      extras() { return this.app.extras },
      content() { return this.app.content },
      selectedExtras() {
        const extras = this.cart ? this.cart.lineItems.filter(item => item.snapshot && item.snapshot.product.typeId === '2') : [];
        return extras.reduce((a, b) => a + b.salePrice*b.qty, 0)
      }
    },
    watch: {
      hubspotVars: {
        handler(val){
        }
      },
      cart: {
        immediate: true,
        handler(cart) {
          this.stopEnter();
          this.enquiry['email']             = cart ? cart.email : null
          this.enquiry['addressText']       = cart ? cart.addressText : null
          this.enquiry['userMessage']       = cart ? cart.userMessage : null
//          this.enquiry['accessibleByTruck'] = cart ? cart.accessibleByTruck : null
          this.enquiry['doYouOwnASite']     = cart ? cart.doYouOwnASite : null

          this.enquiry['shippingMethodHandle']     = (cart && cart.shippingMethodName) ? cart.shippingMethodHandle : null
//console.log( 'cart running' );

           if(this.shippingMethods && this.shippingMethods.length == 0 && cart && cart.availableShippingMethodOptions){
              this.setAddressMethods(cart.availableShippingMethodOptions);
//console.log( 'cart setting shippingMethods', this.shippingMethods,cart.availableShippingMethodOptions);
           }

        }
      },
      content: {
        immediate: true,
        handler(content) {
          
          const sh = content ? content.shippingAddress : null
          this.enquiry['firstName'] = sh ? sh.firstName : null
          this.enquiry['lastName'] = sh ? sh.lastName : null
          this.enquiry['phone']    = sh ? sh.phone    : null
          this.enquiry['address1'] = sh ? sh.address1 : null
          this.enquiry['address2'] = sh ? sh.address2 : null
          this.enquiry['address3'] = sh ? sh.address3 : null
          this.enquiry['city']     = sh ? sh.city     : null
          this.enquiry['zipCode']  = sh ? sh.zipCode  : null


          const other = content ? content.other : null
          this.enquiry['salesperson']  = other ? other.defaultUser : null;
          this.enquiry['defaultCountry']  = other ? other.defaultCountry : null;
//console.log( 'content running');
          if(this.shippingMethods && this.shippingMethods.length == 0 && content && content.shippingMethods){
            this.setAddressMethods(content.shippingMethods);
//console.log( 'content setting shippingMethods', this.shippingMethods);
          }

        }
      },
      open(open) {
        if (this.$refs.enquiry && open) {
          scrollLock(this.$refs.enquiry)
        } else {
          scrollUnLock()
        }
      }
    },
    methods: {
      stopEnter(){
        const inputs = document.querySelectorAll('.modal-inner input:not([type="textarea"])');
        if( inputs.length > 0 && this.stopEnterRun === false )
        {
          this.stopEnterRun = true
          inputs.forEach(input => {
            input.addEventListener('keydown', (event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
              }
            });
          });
        }
      },
      setEmailPhone(e) {
        this.emailPhoneVal = e.target.value;

        if (this.emailPhoneVal.indexOf('@') !== -1) this.enquiry.email = this.emailPhoneVal;
        else this.enquiry.phone = this.emailPhoneVal;
      },
      setAddressMethods(addressOptions){
        this.shippingMethods = addressOptions ? Object.values(addressOptions).map(method => {
            return {
              name: method.name,
              value: method.handle
            }
          }).sort((a,b) => {
            return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
          }): []
      },

      updateExtrasInCart(extra, added) {
        const inCart = this.cart.lineItems.find(item => item.purchasableId === extra.id);

        if (!added && inCart) {
          this.app.removeLineItem({ id: inCart.id, qty: inCart.qty }).then(() => {
            extra.qty = 1
          })
        } else if (inCart) {
          this.app.removeLineItem({ id: inCart.id, qty: inCart.qty }).then(() => {
            if (extra.qty < 11) {
              this.app.addToCart({ pid: extra.id, qty: extra.qty })
              extra.added = true
            }
          })
        } else if (extra.qty < 11) {
          this.app.addToCart({ pid: extra.id, qty: extra.qty })
          extra.added = true
        }
      },

      saveFields() {
        const fields = {
          billingAddressSameAsShipping:1,
          email:                       this.enquiry.email,
          shippingMethodHandle:        this.enquiry.shippingMethodHandle,
          'shippingAddress[firstName]': this.enquiry.firstName,
          'shippingAddress[lastName]': this.enquiry.lastName,
          'shippingAddress[phone]':    this.enquiry.phone,
          'shippingAddress[address1]': this.enquiry.address1,
          'shippingAddress[address2]': this.enquiry.address2,
          'shippingAddress[address3]': this.enquiry.address3,
          'shippingAddress[city]':     this.enquiry.city,
          'shippingAddress[zipCode]':  this.enquiry.zipCode,
          'shippingAddress[countryId]':this.enquiry.defaultCountry,
          'fields[userMessage]':       this.enquiry.userMessage,
          'fields[addressText]':       this.enquiry.addressText,
//          'fields[accessibleByTruck]': this.enquiry.accessibleByTruck === true ? 1 : 0,
          'fields[doYouOwnASite]':     this.enquiry.doYouOwnASite === true ? 1 : 0,
          'fields[salesperson][]':     this.enquiry.salesperson,
        }

        this.app.craftPost(fields)
      },

      updateAddress(fields) {
        this.enquiry.address1 = `${fields.street_number} ${fields.route}`;
        this.enquiry.address2 = fields.sublocality_level_1
        this.enquiry.address3 = fields.administrative_area_level_1
        this.enquiry.city     = fields.locality
        this.enquiry.zipCode  = fields.postal_code

        this.saveFields();
      },

      submit() {
        this.$v.enquiry.$touch()
        if (!this.$v.enquiry.$invalid) {
          this.sending = true
          this.app.craftPost({
            action: 'commerce/payments/pay',
            gatewayId: 1,
            orderEmail: this.enquiry.email,
            number: '4242424242424242',
            month: '12',
            year: new Date().getFullYear(),
            cvv: '111',
            lastName: 'Enquiry',
            firstName: 'Person'
          }).then(() => {
            this.sending = false;
            this.submitted = true;
            this.$v.enquiry.$reset()
            this.$root.extras.forEach(e => {
              e.added = false
            })
            this.app.craftPost()

            // send event trigger to GTM
            window.dataLayer.push({
              "event": "submission-buy",
              "page": {
                "path": window.location.pathname,
                "title": document.title
              }
            });

          })
        }
      },

      close() {
        this.$root.open = false;
        if (this.submitted) {
          // Reset the submitted state
          this.submitted = false;
        }
      },

      resetForm() {
        this.submitted = false;
      }

    }
  }
</script>