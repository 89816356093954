<template>
  <div class="input-wrap" :class="{ 'has-error':inError, select:type === 'select', textarea:type === 'textarea' }">
    <label v-if="label" class="input-label">{{ label }}</label>
    <select
      v-if="type === 'select'"
      ref="input"
      class="input"
      :class="value === '' ? 'is-empty' : ''"
      :name="name"
      :value="value"
      @change="change"
    >
      <option value="" disabled selected>{{ placeholder ? placeholder : 'Click to select' }}</option>
      <option v-for="(option, i) in options" :key="i" :value="option.value" :selected="option.value === value">{{ option.label ? option.label :  option.name }}</option>
    </select>
    <textarea
      v-else-if="type === 'textarea'"
      ref="input"
      class="input textarea"
      :name="name"
      cols="30"
      rows="10"
      :placeholder="placeholder"
      @input="input"
      @change="$emit('change', $event)"
    >{{ value }}</textarea>
    <input
      v-else
      ref="input"
      class="input"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      @input="input"
      @change="$emit('change', $event)"
    >
    <template v-if="errorText && inError">
      <span class="error">{{ errorText }}</span>
    </template>
  </div>
</template>

<script>
  export default {
    name: "text-field",
    props: {
      type: { default: 'text' },
      value: { default: '' },
      label: { default: null },
      placeholder: { default: null },
      name: { default: null },
      errorText: { default: null },
      inError: { default: false },
      options: { default() { return [] } }
    },
    methods: {
      input(event) {
        this.$emit('input', event.target.value);
      },
      change(event) {
        this.$emit('input', event.target.value);
        this.$emit('change', event.target.value);
      },
      focusOn() {
        this.$refs.input.focus();
      }
    }
  }
</script>