import Vue from 'vue/dist/vue.min.js';
//import Vue from 'vue';
import select from 'dom-select';
import Vuelidate from 'vuelidate'
import App from './App.vue';
import Forms from './Forms.vue';
import qs from 'qs'
import axios from '../helpers/axios'
import currency from "currency.js"
import SmoothScroll from 'smooth-scroll'

Vue.use(Vuelidate);

export const app = new Vue({
  el: '#app',
  data: {
    open: false,
    cart: null,
    order: null,
    loading: false,
    content: null,
    product: null,
    extras: [],
    handle: null,
    formHandle: null,
    unit: false,
    housePid: null,
    createdHasRun: false
  },
  methods: {
    loadData() {
      if (!this.createdHasRun && window.location.pathname !== '/') {
        axios.get('/api/data').then(({ data }) => {
          this.content = data
          this.getProduct()
          this.createdHasRun = true
        })
      }
    },
    async craftPost(payload, notcart) {
      payload = typeof payload === 'object' ?
        qs.stringify({...payload, ...!payload.hasOwnProperty('action') ? { action: 'commerce/cart/update-cart' } : {}})
        : !payload ? qs.stringify({ action: 'commerce/cart/update-cart' })
        : payload;
      return await axios.post(window.location, payload, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
          'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest'
        },
      }).then(({ data }) => {
        this.cart = data.cart;
        this.$emit('cart', data.cart);
        if (data.redirect) window.location = data.redirect;
        return notcart ? data : data.cart
      })
    },

    async addToCart(item) {
      return await this.craftPost({
        purchasableId: item.pid,
        qty: item.qty
      });
    },

    async updateLineItem(item) {
      return await this.craftPost({
        ['lineItems['+item.id+'][qty]']: item.qty
      });
    },

    async removeLineItem(item) {
      return await this.craftPost({
        ['lineItems['+item.id+'][remove]']: 1
      });
    },

    async removeLineItems(ids) {
      let query = 'action=commerce/cart/update-cart';
      if (this.cart && ids) {
        ids.forEach(id => {
          query += '&lineItems['+id+'][remove]=1';
        })
      } else if (this.cart) {
        this.cart.lineItems.forEach(lineItem => {
          query += '&lineItems['+lineItem.id+'][remove]=1';
        })
      }

      return await this.craftPost(query)
    },

    money(val, positive) {
      positive = positive || false;

      return currency(val, {
        separator: ',',
        symbol: '$',
        formatWithSymbol: true,
        negativePattern: positive ? `!0` : `-!#`
      }).format().replace('.00','');
    },

    smooth(el) {
      if (el) {
        const pos = el.getBoundingClientRect().top;
        const scroll = new SmoothScroll();
        if (pos < 200) {
          scroll.animateScroll(el, false, {
            offset: 200,
            updateURL: false,
            speed: 600,
            durationMax: 600
          });
        }
      }
    },

    focusFirstStatus(component) {
      if (component.inError) {
        this.smooth(component.$el);
        setTimeout(function () {
          if (component.focusOn) component.focusOn();
        }, 600);
        return true;
      }
      let focused = false;
      component.$children.some((childComponent) => {
        focused = this.focusFirstStatus(childComponent);
        return focused;
      });
      return focused;
    },

    processProduct() {
      if (this.cart && this.product) {
        this.extras = this.product.extras.map(extra => {
          const inCart = this.cart.lineItems.find(item => item.purchasableId === extra.id);
          return {
            ...extra,
            qty: 1,
            added: false
          }
        })

        //initally remove all the extras - as we're processing a new product
        const extras = this.cart.lineItems.filter(item => item.snapshot.product.typeId === '2');
        this.removeLineItems(extras.map(e => e.id))

        const housesInCart = this.cart.lineItems.filter(item => item.snapshot.product.typeId === '1');

        if (housesInCart.length) {
          this.removeLineItems(housesInCart.map(h => h.id)).then(() => {
            this.addToCart({ pid: this.product.pid, qty: 1 })
          })
        } else this.addToCart({ pid: this.product.pid, qty: 1 })
      }
    },

    getProduct() {
      this.product = this.content && this.housePid ? this.content.products.find(p => Number(p.pid) === Number(this.housePid)) : null
      const openQuery = window.location.search === '?estimate'


      if (openQuery) {
        const formButton = select('.open-form')
        if (formButton) {
          this.formHandle = formButton.dataset.form
          this.unit = formButton.dataset.unit
          this.open = true
        } else {
          this.open = true
          this.formHandle = false
        }
      }

      if (this.product) {
        if (this.cart) {
          this.processProduct()
        } else {
          this.craftPost().then(() => {
            this.processProduct()
          })
        }
      }
    }
  },

  created() {
    this.loadData()
  },
  render: h => h(App)
});

export const formApp = (el) => {
  return new Vue({
    el,
    data: {
      handle: el.dataset.form,
      fileName: el.dataset.file ? el.dataset.file : null,
      unit: el.dataset.unit ? el.dataset.unit : null,
      asModal: el.dataset.asmodal ? el.dataset.asmodal : false
    },
    render: h => h(Forms)
  })
}