<template>
  <div class="input-wrap" :class="{ 'has-error':inError }">
    <template v-if="type === 'checkbox_group'">
      <label class="input-label">{{ label }}</label>
      <div class="checkbox-group">
        <label v-for="(option, i) in options" :key="`${name}-${i}`" class="checkbox">
          <input type="checkbox" :name="name" v-model="values" :value="option.value" @change="change">
          <div class="has-checkbox"><span>{{ option.label }}</span></div>
        </label>
      </div>
    </template>
    <label v-else class="checkbox">
      <input type="checkbox" v-model="val" @change="change">
      <div class="has-checkbox"><span>{{ label }}</span></div>
    </label>
    <template v-if="errorText && inError">
      <span class="error">{{ errorText }}</span>
    </template>
  </div>
</template>

<script>
  export default {
    name: "checkbox-field",
    props: {
      value: { default: false },
      label: { default: null },
      type: { default: 'checkbox' },
      options: { default() { return [] } },
      name: { default: null },
      errorText: { default: null },
      inError: { default: false }
    },
    data() {
      return {
        values: null,
          val: false
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(value) {
          this.val = value !== '' ? value : false
          this.values = value
        }
      }
    },
    methods: {
      change() {
        if (this.type === 'checkbox_group') this.$emit('input', this.values);
        else {
          this.$emit('input', this.val);
        }
      }
    },
    focusOn() {
      this.$refs.input.focus();
    }
  }
</script>