import "core-js/stable";
import "regenerator-runtime/runtime";

import "../scss/style.scss";
import barba from "@barba/core/dist/barba";
import select from "dom-select";
import { classy as cl } from "./helpers/classy";
import { swiperLightbox } from "./helpers/swiperLightbox";
import { scrollLock, scrollUnLock } from "./helpers/scrollLock";
import throttle from "lodash.throttle";
import Spoilers from "./helpers/spoilers";
import SmoothScroll from "smooth-scroll";
import Stickyfill from "stickyfilljs/dist/stickyfill.min";
import LazyLoad from "./helpers/lazyload.min";
import Plyr from "plyr";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Swiper from "swiper/js/swiper.min";
import { app as enquiryApp, formApp } from "./shop";
import { createPopper } from "@popperjs/core";

var initialGTM = false;

(function(doc, win) {
  require("viewport-units-buggyfill").init();
  window.openModal = false;
  window.dataLayer = window.dataLayer || [];
  var app = {};

  function cookieConsentBannerCloseBtn() {
    const cookieConsentBannerCloseBtnEl = document.querySelector(
      ".cookie-consent-container > button"
    );

    cookieConsentBannerCloseBtnEl.addEventListener("click", () => {
      const cookieConsentBannerEl = document.querySelector(
        ".cookie-consent-container"
      );
      cookieConsentBannerEl.style.display = "none";
    });
  }
  const nav = function() {
    const burger = select(".burger");
    const header = select(".header");

    function checkCover(parent) {
      const cover = select(".is-cover", parent);

      if (cover && cover.classList.contains("white-color"))
        cl(header).addCl("white-color");
      else cl(header).rmCl("white-color");
    }

    if (burger)
      burger.addEventListener("click", function() {
        if (burger.classList.contains("open")) {
          window.openModal = false;
          scrollUnLock();
        } else {
          window.openModal = header;
          scrollLock(header);
        }
        cl(header).toCl("open");
        cl(burger).toCl("open");
        cl(".mobile-drop").rmCls("open");
      });

    select.all(".mobile-drop", header).forEach((m) => {
      m.addEventListener("click", (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        select.all(".mobile-drop", header).forEach((md) => {
          if (md !== m) cl(md).rmCl("open");
        });
        cl(m).toCl("open");
      });
    });

    this.close = function() {
      cl(header).rmCl("open");
      cl(burger).rmCl("open");
      window.openModal = false;
      scrollUnLock();
      cl(".mobile-drop").rmCls("open");
    };

    this.scroll = function() {
      if (window.pageYOffset > 200) cl(header).addCl("fixed");
      else cl(header).rmCl("fixed");
      if (window.pageYOffset > 400) cl(header).addCl("show");
      else cl(header).rmCl("show");
      // if (cover) {
      //   const coverBottom = cover.getBoundingClientRect().bottom;
      //   if (coverBottom > 105) cl(header).rmCl('is-slim');
      //   else cl(header).addCl('is-slim')
      // }
    };

    this.update = function(parent) {
      checkCover(parent);
    };
    checkCover();
  };

  function activenav() {
    var firstSegment = location.pathname.split("/")[1];
    var lastSegment = location.pathname.split("/").pop();
    select.all(".header a, .nav a").forEach(function(nav) {
      var linkSegments = nav.pathname.split("/");
      var linkFirstSegment =
        linkSegments.length > 1 && linkSegments[1] !== ""
          ? linkSegments[1]
          : false;
      var linkSecondSegment =
        linkSegments.length > 2 && linkSegments[2] !== ""
          ? linkSegments[2]
          : false;

      if (linkSecondSegment && linkSecondSegment === lastSegment)
        cl(nav).addCl("active");
      else if (
        linkFirstSegment &&
        !linkSecondSegment &&
        linkFirstSegment === firstSegment
      )
        cl(nav).addCl("active");
      else cl(nav).rmCl("active");
    });
  }

  function smooth(el, offset, speed) {
    if (el) {
      const scroll = new SmoothScroll();
      scroll.animateScroll(el, false, {
        offset: offset || 200,
        updateURL: false,
        speed: speed || 600,
        durationMax: speed || 600,
      });
    }
  }

  function setSmooth(parent) {
    select.all(".smooth-scroll", parent).forEach((anchor) => {
      anchor.addEventListener("click", (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        const target = select(anchor.hash);
        if (target) smooth(target, 40, 1000);
      });
    });
  }

  function stickyEls(parent) {
    parent = parent || false;
    Stickyfill.removeAll();
    const stickys = select.all(".sticky", parent);
    if (stickys.length) Stickyfill.add(stickys);
  }

  app.swipers = [];
  function swipers(parent) {
    app.swipers.forEach((sw) => sw.destroy());

    const homeSwiper = select(".home-swiper", parent);
    if (homeSwiper) {
      const buttons = select.all("[data-slide-to]", homeSwiper);
      const sections = select.all("[data-swiper-section]", homeSwiper);
      app.swipers.push(
        new Swiper(homeSwiper, {
          speed: 600,
          navigation: {
            nextEl: select(".control-right", homeSwiper),
            prevEl: select(".control-left", homeSwiper),
          },
          on: {
            init: function() {
              cl(buttons[this.activeIndex]).addCl("active");
              buttons.forEach((b) => {
                b.addEventListener("click", () => {
                  this.slideTo(b.dataset.slideTo);
                });
              });
              sections.forEach((s) => {
                s.addEventListener("click", () => {
                  this.slideTo(s.dataset.swiperSection);
                });
              });
            },
            slideChange: function() {
              cl(buttons).rmCls("active");
              cl(buttons[this.activeIndex]).addCl("active");

              if (sections.length > 1) {
                if (
                  Number(sections[1].dataset.swiperSection) <= this.activeIndex
                ) {
                  cl(sections[1]).addCl("active");
                  cl(sections[0]).rmCl("active");
                } else {
                  cl(sections[0]).addCl("active");
                  cl(sections[1]).rmCl("active");
                }
              }

              select
                .all(".home-swiper__conditional .floating-buttons", homeSwiper)
                .forEach((bts) => {
                  const active = !!Array.from(bts.children).find((el) =>
                    el.classList.contains("active")
                  );
                  if (active) cl(bts).rmCl("d-none");
                  else cl(bts).addCl("d-none");
                });

              if (app.floatingButtons) app.floatingButtons.updateActive();
            },
          },
        })
      );
    }

    select.all(".fade-swiper").forEach((fadeSwiper) => {
      let speed = fadeSwiper.dataset.speed;
      let autoplay = fadeSwiper.dataset.autoplay;

      app.swipers.push(
        new Swiper(fadeSwiper, {
          effect: "fade",
          speed: speed && Number(speed) < 6000 ? Number(speed) : 1500,
          loop: true,
          autoplay: autoplay
            ? {
                delay: autoplay,
              }
            : false,
          navigation: {
            nextEl: select(".control-right", fadeSwiper),
            prevEl: select(".control-left", fadeSwiper),
          },
        })
      );
    });

    setTimeout(function() {
      app.swipers.forEach((sw) => sw.update());
    }, 200);
  }

  const floatingButtons = function(parent) {
    function setFloater(el, floater) {
      const elRect = el.getBoundingClientRect();
      const parentLeft = el.parentElement.getBoundingClientRect().left;
      const elLeft = elRect.left;
      const offset = elLeft - parentLeft;

      floater.style.cssText = `width: ${elRect.width}px; left: ${offset}px`;
    }

    const wraps = select.all(".floating-buttons", parent);
    const wrapsArr = [];
    wraps.forEach((wrap) => {
      const buttons = select.all(".floating-button", wrap);
      const floater = select(".floater", wrap);

      wrapsArr.push({
        wrap: wrap,
        floater: floater,
        buttons: buttons,
      });

      buttons.forEach((b) => {
        b.addEventListener("mouseover", () => {
          setFloater(b, floater);
        });
      });

      wrap.addEventListener("mouseover", () => {
        cl(wrap).addCl("is-hovered");
      });

      wrap.addEventListener("mouseout", () => {
        cl(wrap).rmCl("is-hovered");
        buttons.forEach((b) => {
          if (b.classList.contains("active")) setFloater(b, floater);
        });
      });
    });

    this.updateActive = function() {
      wrapsArr.forEach((wrap) => {
        wrap.buttons.forEach((b) => {
          if (b.classList.contains("active")) setFloater(b, wrap.floater);
        });
      });
    };

    this.updateActive();
    setTimeout(this.updateActive, 50);
  };

  app.msnry = null;
  function setGrid(parent) {
    if (app.msnry) app.msnry.destroy();

    //allows for multiple masonary on one page.
    var grids = select.all(".grid-wrap", parent);
    if (grids) {
      grids.forEach((g) => {
        app.msnry = new Masonry(g, {
          itemSelector: ".grid-item",
          columnWidth: ".grid-sizer",
          percentPosition: true,
        });
        app.msnry.layout();
      });
    }

    // var grid = select('.grid-wrap', parent);
    // if (grid) {
    //   app.msnry = new Masonry(grid, {
    //     itemSelector: '.grid-item',
    //     columnWidth: '.grid-sizer',
    //     percentPosition: true
    //   });
    //   app.msnry.layout()
    // }
  }

  function setLazy(parent) {
    const images = select.all(".grid-image img", parent);
    new LazyLoad(images);
  }

  app.players = [];
  function setPlayers(parent) {
    app.players.forEach((p) => p.destroy());
    app.players = select
      .all(".player:not(.player-in-swiper-slide)")
      .map((p) => {
        const player = new Plyr(p, {
          controls: ["play-large", "play", "progress", "volume", "fullscreen"],
          hideControls: false,
          resetOnEnd: true,
          tooltips: { controls: false, seek: false },
        });
        player.on("ready", () => {
          player.toggleControls(false);
        });
        player.on("pause", () => {
          player.toggleControls(false);
        });
        player.on("play", () => {
          player.toggleControls(true);
        });
        if (p.dataset.poster) player.poster = p.dataset.poster;
        return player;
      });
  }

  function setMaps(parent) {
    function setMap(map) {
      var lat =
        typeof map.dataset.lat !== "undefined" ? map.dataset.lat : false;
      var lng =
        typeof map.dataset.lon !== "undefined" ? map.dataset.lon : false;
      var zoom =
        typeof map.dataset.zoom !== "undefined" ? map.dataset.zoom : false;

      if (google && lat && lng) {
        var center;
        var pos = new google.maps.LatLng(lat, lng);
        var map = new google.maps.Map(map, {
          center: pos,
          zoom: Number(zoom),
          disableDefaultUI: true,
          draggable: true,
          zoomControl: true,
          scrollwheel: false,
          styles: [
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
            },
            {
              featureType: "landscape",
              elementType: "geometry",
              stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.fill",
              stylers: [{ color: "#ffffff" }, { lightness: 17 }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                { color: "#ffffff" },
                { lightness: 29 },
                { weight: 0.2 },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [{ color: "#ffffff" }, { lightness: 18 }],
            },
            {
              featureType: "road.local",
              elementType: "geometry",
              stylers: [{ color: "#ffffff" }, { lightness: 16 }],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#dedede" }, { lightness: 21 }],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                { visibility: "on" },
                { color: "#ffffff" },
                { lightness: 16 },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                { saturation: 36 },
                { color: "#333333" },
                { lightness: 40 },
              ],
            },
            { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
            },
            {
              featureType: "administrative",
              elementType: "geometry.fill",
              stylers: [{ color: "#fefefe" }, { lightness: 20 }],
            },
            {
              featureType: "administrative",
              elementType: "geometry.stroke",
              stylers: [
                { color: "#fefefe" },
                { lightness: 17 },
                { weight: 1.2 },
              ],
            },
          ],
        });

        var markerSize = 12;
        var markerIcon = {
          path: `M${markerSize}, ${markerSize} m -${markerSize}, 0 a ${markerSize},${markerSize} 0 1,0 ${markerSize *
            2},0 a ${markerSize},${markerSize} 0 1,0 -${markerSize * 2},0`,
          fillColor: "#EC2623",
          fillOpacity: 1,
          anchor: new google.maps.Point(markerSize, markerSize),
          strokeWeight: 0,
          scale: 1,
        };

        google.maps.event.addListener(map, "idle", function() {
          center = map.getCenter();
        });

        google.maps.event.addDomListener(window, "resize", function() {
          google.maps.event.trigger(map, "resize");
          map.setCenter(center);
        });

        var mapMarker = new google.maps.Marker({
          position: pos,
          map: map,
          draggable: false,
          icon: markerIcon,
        });
      }
    }
    select.all(".gmap", parent).forEach(setMap);
  }

  app.tooltips = [];
  const doToolTips = function(parent) {
    app.tooltips.forEach((t) => {
      t.popperInstance.destroy();
      t.tooltip.remove();
    });

    select.all(".tooltip-button", parent).forEach((button) => {
      const tooltip = document.createElement("div");
      tooltip.innerHTML = button.dataset.tooltip;
      tooltip.className = "tooltip";
      document.body.appendChild(tooltip);

      const popperInstance = createPopper(button, tooltip, {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
          {
            name: "eventListeners",
            options: {
              scroll: false,
              resize: false,
            },
          },
        ],
      });

      app.tooltips.push({
        popperInstance,
        tooltip,
      });

      const showEvents = ["mouseenter", "focus"];
      const hideEvents = ["mouseleave", "blur"];

      showEvents.forEach((event) => {
        button.addEventListener(event, () => {
          popperInstance.update();
          tooltip.setAttribute("data-show", "");
        });
      });

      hideEvents.forEach((event) => {
        button.addEventListener(event, () => {
          tooltip.removeAttribute("data-show");
        });
      });
    });
  };

  app.forms = null;
  function setApps(parent) {
    if (app.forms) app.forms.$destroy();

    const forms = select(".forms", parent);
    if (forms) {
      app.forms = formApp(forms);
    }

    select.all(".open-enquiry").forEach((click) => {
      // click.addEventListener("click", (ev) => {
      //   ev.preventDefault();
      //   ev.stopPropagation();

      //   if (app.enquiryApp) {
      //     app.enquiryApp.open = true;
      //     app.enquiryApp.formHandle = false;
      //   }
      // });
    });

    select.all(".open-form").forEach((click) => {
      // click.addEventListener("click", (ev) => {
      //   ev.preventDefault();
      //   ev.stopPropagation();

      //   if (app.enquiryApp && click.dataset.form) {
      //     app.enquiryApp.open = true;
      //     app.enquiryApp.formHandle = click.dataset.form;
      //     app.enquiryApp.hubspotVars = JSON.parse(click.dataset.hubspotVars);
      //     app.enquiryApp.unit = click.dataset.unit;
      //   }
      // });
    });
  }

  // function setProduct(parent) {
  //   const house = select(".is-product-page", parent);
  //   if (house && house.dataset.pid && app.enquiryApp) {
  //     app.enquiryApp.housePid = house.dataset.pid;
  //     app.enquiryApp.getProduct();
  //   }
  // }
  function setProduct() {
    const house = select(".is-product-page", document.body);
    // if (house && house.dataset.pid)
    // {
    //   const openEnquiryButtons = document.querySelectorAll('.open-enquiry');
    //   let listenerAdded = false;

    //   // if (window.location.search === '?estimate') {
    //   //   // If the `?estimate` query string parameter is present, trigger a click event on the first `.open-enquiry` button
    //   //   const firstButton = openEnquiryButtons[0];
    //   //   if (firstButton && app.enquiryApp) {
    //   //     app.enquiryApp.housePid = house.dataset.pid;
    //   //     app.enquiryApp.getProduct();
    //   //     listenerAdded = true;
    //   //   }
    //   // } else {
    //   //   openEnquiryButtons.forEach(button => {
    //   //     // Otherwise, attach the event listener to all `.open-enquiry` buttons
    //   //     if (!listenerAdded) {
    //   //       button.addEventListener('click', function() {
    //   //         if (app.enquiryApp) {
    //   //           app.enquiryApp.housePid = house.dataset.pid;
    //   //           app.enquiryApp.getProduct();
    //   //           listenerAdded = true;
    //   //         }
    //   //       });
    //   //     }
    //   //   });
    //   // }
    // }
  }
  

  function GTMpageView() {
    window.dataLayer.push({
      event: "pageview",
      page: {
        path: window.location.pathname,
        title: document.title,
      },
    });
  }

  function addthisUpdate() {
    if (typeof addthis !== "undefined") {
      addthis.layers.refresh();
    }
  }

  if (initialGTM == false) {
    //initial page load - send a the page view.
    initialGTM = true;
    GTMpageView();
  }

  function outBarba() {
    app.enquiryApp = enquiryApp;
    app.nav = new nav();
    setProduct();
  }

  const hubspotForm = (container)=>{
    const hubspotFormElements = container.querySelectorAll(".hubspot-form");
    if (hubspotFormElements.length > 0) {
      const scriptSrc = "https://js.hsforms.net/forms/embed/v2.js";
      const existingScriptTag = document.querySelector(`script[src="${scriptSrc}"]`);
  
      const initializehubspotForms = () => {
        hubspotFormElements.forEach(function (form) {
          const vars = JSON.parse(form.dataset.hubspotVars)
          const script = document.createElement("script");
          script.innerHTML = `
            hbspt.forms.create({
                region: "${vars.region}",
                portalId: "${vars.portalId}",
                formId: "${vars.formId}",
                onFormReady: function(form) {
                  let iFrame = form.querySelector('.hbspt-form iframe');
                  if (iFrame) {
                    setTimeout(()=>{
                      iFrame.style.height = "1000px";
                    },1000)
                  }
              }
            });
        `;
        form.appendChild(script);
        });
      };
  
      if (!existingScriptTag) {
        const script = document.createElement("script");
        script.src = scriptSrc;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
  
        script.onload = initializehubspotForms;
      } else {
        initializehubspotForms();
      }
    }
  }

 



  function onBarba(parent) {
    activenav();
    cookieConsentBannerCloseBtn();

    if (parent) {
      app.floatingButtons = new floatingButtons(parent);
      app.spoilers = new Spoilers(parent);
      app.nav.update(parent);
      app.lightBox = new swiperLightbox(parent);
      stickyEls(parent);
      swipers(parent);
      setApps(parent);
      setPlayers(parent);
      setGrid(parent);
      setLazy(parent);
      setMaps(parent);
      setSmooth(parent);
      doToolTips(parent);
      hubspotForm(parent);
      addthisUpdate();
      formModalToggle();
    }

    if (win.location.hostname === "localhost") {
      select.all("a").forEach((a) => {
        a.href = a.href.replace("http://houseme.test", "");
      });
      activenav();
    }
  }

  const formModalToggle = () =>{
    let formOpenButtons = document.querySelectorAll('.open-form');
    let opened = false;
    formOpenButtons.forEach(function(button) {
        button.addEventListener('click', function(event) {
            openFormModal(event.target); // Pass the clicked button to the function
        });
        if (button.textContent.toLowerCase().includes('request an estimate') && window.location.search === '?estimate' && opened === false) {
        
          opened = true;
          openFormModal(button);
        }
    });

  
    
    let closeButton = document.querySelector('.close.rounded-button');
    if (closeButton) {
        closeButton.addEventListener('click', closeFormModal);
    }
    
    function openFormModal(button) {
      if (document.querySelector('.estimation-modal .modal-inner .hubspot-form')) {
        document.querySelector('.estimation-modal .modal-inner .hubspot-form').remove();
    }  
      
      let modal = document.querySelector('.estimation-modal');
        modal.classList.add('open');
        
        
        let formModalInnerContainer = document.querySelector('.estimation-modal .modal-inner');
        if (formModalInnerContainer) {
            const div = document.createElement('div');
            div.classList.add('hubspot-form');
            
            // Use the dataset from the clicked button
            div.setAttribute('data-hubspot-vars', button.dataset.hubspotVars);
    
            formModalInnerContainer.appendChild(div);
            
            // Make sure the hubspotForm function is defined somewhere
            hubspotForm(document);
        }
    }
      
      // Close the modal
      function closeFormModal(event) {
          if (event) {
              event.stopPropagation();
          }
          let modal = document.querySelector('.estimation-modal');
          modal.classList.remove('open');
          scrollUnLock();
      }
    
  }

  doc.addEventListener("DOMContentLoaded", function() {


    cl(".curtain").rmCl("open");
    outBarba();

    win.addEventListener(
      "scroll",
      throttle(function() {
        if (app.nav) app.nav.scroll();
      }, 50)
    );

    win.addEventListener(
      "resize",
      throttle(function() {
        if (app.nav) app.nav.close();
        if (app.floatingButtons) app.floatingButtons.updateActive();
      }, 300)
    );

    win.addEventListener("load", function() {
      //do we have a regions lightbox request?
      const regionPopup = window.location.search === "?regions";
      if (regionPopup) {
        const regionButton = document.querySelector('[data-lightbox-qs="areas available"]');
        regionButton.click();
      }
    });

    barba.init({
      timeout: 5000,
      prefetchIgnore: true,
      transitions: [
        {
          name: "transition",
          from: {
            custom: ({ trigger }) => {
              return window.openModal === false;
            },
          },
          leave() {
            const done = this.async();
            cl(".curtain").addCl("open");
            setTimeout(function() {
              done();
            }, 200);
          },
        },
      ],
    });

    barba.hooks.leave(() => {
      if (app.nav) app.nav.close();
      if (app.lightBox) app.lightBox.destroy();
      if (app.resetForm) app.resetForm();
    });

    barba.hooks.enter((data) => {

      // load the data via the app. Won't load it on the home page as it's not needed
      app.enquiryApp.loadData();
      cl(".curtain").rmCl("open");
      window.scrollTo(0, 0);
      setProduct(data.next.container);

    });

    barba.hooks.afterEnter((data) => {
      onBarba(data.next.container);

      // window.dataLayer.push({
      //   "event": "pageview",
      //   "page": {
      //     "path": window.location.pathname,
      //     "title": document.title
      //   }
      // });

      // Fix for Barba disrupting HubSpot form embeds. Barba restores the original HubSpot snippet after it has fired, and it does not fire again
      // Find the site's custom form wrapper(s)
      // var hubspot_forms = document.querySelectorAll('.hubspot-form');
      // Array.prototype.forEach.call(hubspot_forms, function(el, i) {
      //   var hubspot_form = hubspot_forms[i];
      //   // If this element does not exist, the Hubspot embed has not fired
      //   var hubspot_loaded = hubspot_form.querySelector('.hbspt-form');
      //   if (!hubspot_loaded) {
      //     var vars_tag = hubspot_form.querySelector('script:last-of-type');
      //     var vars = vars_tag.innerHTML;
      //     eval(vars);
      //   }
      // });

    });

    barba.hooks.after((data) => {
      // user has changed page - send new page view
      GTMpageView();
    });
  });
})(document, window);
