import select from 'dom-select';
import { classy as cl } from './classy';
import Swiper from 'swiper/js/swiper.min';
import Plyr from 'plyr';

export const swiperLightbox = function (parent) {

  let players;

  function open(wrInd, clInd) {
    if (modals && modals[wrInd]) {
      const m = modals[wrInd]
      cl(m.modal).addCl('open');
      m.open = true;
      m.swiper.slideTo(clInd, 0)
      players = initPlayers()
      m.swiper.on('slideChange', function () {
        players.map(p => p.pause())
      });
    }
  }

  function close(wrInd) {
    if (modals && modals[wrInd]) {
      const m = modals[wrInd]
      cl(m.modal).rmCl('open')
      m.open = false
      players.map(p => p.destroy())
    }
  }

  function initPlayers() {
    const players = select.all('.player.player-in-swiper-slide').map(p => {
      const player = new Plyr(p, {
        controls: ['play-large','play','progress','volume','fullscreen'],
        hideControls: false,
        resetOnEnd: true,
        tooltips: { controls: false, seek: false }
      })
      player.on('ready', () => {
        player.toggleControls(false);
      })
      player.on('pause', () => {
        player.toggleControls(false);
      })
      player.on('play', () => {
        player.toggleControls(true);
      })
      if (p.dataset.poster) player.poster = p.dataset.poster
      return player
    });
    return players
  }

  const arrow = '<svg enable-background="new 0 0 7.7 13.3" viewBox="0 0 7.7 13.3" xmlns="http://www.w3.org/2000/svg"><path d="m1 13.3c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l5-4.9-5-5c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7c.4-.4 1-.4 1.4 0l5.7 5.7c.4.4.4 1 0 1.4l-5.7 5.6c-.2.2-.4.3-.7.3z"/></svg>'

  const modals = select.all('.lightbox-list', parent).map((wrap, wrInd) => {
    const clicks = select.all('[data-lightbox], [data-lightbox-youtube-video-id]', wrap).map((click, clInd) => {

      click.addEventListener('click', ev => {
        ev.preventDefault()
        ev.stopPropagation()

        open(wrInd, clInd)
      })
      return {
        click,
        url: click.dataset.lightbox,
        youtubeVideoID: click.dataset.lightboxYoutubeVideoId,
        slideClass: click.dataset.slideClass,
        memberName: click.dataset.memberName ? `<p class="title is-2">${click.dataset.memberName}</p>` : null,
        memberPosition: click.dataset.memberPosition ? `<p>${click.dataset.memberPosition}</p>` : '',
        memberDescription: click.dataset.memberDescription ? `<div>${click.dataset.memberDescription}</div>` : ''
      }
    })

    if (!clicks.length) {
      return false
    }

    const modal = document.createElement('div');
    const closeEl = document.createElement('div');
    const cLeft = document.createElement('div');
    const cRight = document.createElement('div');
    const swEl = document.createElement('div');

    modal.className = 'lightbox-modal';
    closeEl.className = 'close rounded-button';
    cLeft.className = 'control-left rounded-button w-arrow';
    cRight.className = 'control-right rounded-button w-arrow';
    swEl.className = 'swiper-container';
    cLeft.innerHTML = arrow;
    cRight.innerHTML = arrow;
    swEl.innerHTML = `
      <div class="swiper-wrapper">
        ${clicks.map(c => {
          const teamMemberData = c.memberName ? `<div>${c.memberName}${c.memberPosition}${c.memberDescription}</div>` : '';
          return ! c.url && c.youtubeVideoID ? `
          <div class="swiper-slide">
            <div class="player player-in-swiper-slide" data-plyr-provider="youtube" data-plyr-embed-id="${ c.youtubeVideoID }" ${ c.poster ? 'data-poster="' + c.poster + '"' :  '' }"></div>
          </div>
        ` : `
          <div class="swiper-slide ${c.slideClass}">
            <img data-src="${c.url}" class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div>
            ${teamMemberData}
          </div>
        `}).join('')}
      </div>
    `;

    modal.appendChild(closeEl)
    modal.appendChild(swEl)
    if (clicks.length > 1) {
      modal.appendChild(cLeft)
      modal.appendChild(cRight)
    }
    document.body.appendChild(modal);

    closeEl.addEventListener('click', (ev) => {
      close(wrInd)
    })

    // modal.addEventListener('click', (ev) => {
    //   if (ev.target.classList.contains('lightbox-modal')) close(wrInd)
    // })

    const swiper = new Swiper(swEl, {
      preloadImages: false,
      lazy: true,
      speed: 500,
      spaceBetween: 20,
      keyboard: true,
      navigation: {
        nextEl: select('.control-right', modal),
        prevEl: select('.control-left', modal)
      }
    })

    return {
      modal,
      swiper,
      open: false
    }
  })

  

  this.destroy = function () {
    modals.forEach(m => {
      m.swiper.destroy();
      m.modal.remove()
    })
  }
}
